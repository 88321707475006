<template>
  <b-overlay :show="isFetchingTransaction">
    <b-card>
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h6>
          {{ `${$t('fields.result')} (${filteredList.length})` }}
        </h6>
      </div>
      <b-table
        :items="filteredList"
        :fields="fields"
        responsive
        show-empty
      >
        <template #cell(#)="data">
          {{  (limit * (page - 1)) + (data.index + 1) }}
        </template>
        <template #cell(createdAt)="data">
          {{ data.value | datetime }}
        </template>
        <template #cell(username)="data">
          <b-link :to="data.item.user_id?`/players/detail/${data.item.user_id}`:`/players/list`">
            {{ `${data.value}`}}
          </b-link>
        </template>
        <template #empty="">
          <p class="text-center text-muted">{{
            $t('messages.nothing_here')
          }}</p>
        </template>
        <template #cell(source)="data">
          <b-badge :variant="data.value === 'loss' ? 'danger' : 'success'">
            {{ data.value }}
          </b-badge>
        </template>

      </b-table>

      <!-- pagination -->
      <PaginationInput :per-page="limit" :total="totalRow" @update="(val) => currentPage = val"/>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: 'CashbackTransaction',
  data() {
    return {
      currentPage: 1,
      limit: 50,
      page: 1,
      fields: [
        '#',
        {
          key: 'createdAt',
          label: `${this.$t('fields.createdAt')}`,
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'username',
          label: `${this.$t('user.username')}`,
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'agentUsername',
          label: `${this.$t('agent.name')}`,
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'amount',
          label: `${this.$t('fields.amount')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'source',
          label: `${this.$t('fields.source')}`,
          class: 'text-center',
          thStyle: {
            minWidth: '80px',
          },
        },
        {
          key: 'cashback',
          label: `${this.$t('cashback.cashback_value')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '100px',
          },
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetchingTransaction: (state) =>
        state.cashback.isFetchingCashbackTransaction,
    }),
    ...mapGetters(['cashbackTransaction']),
    filteredList() {
      return this.cashbackTransaction.items || []
    },
    totalRow() {
      return this.cashbackTransaction?.totalCount || 0
    },
  },
  watch: {
    page(val) {
      if (val) {
        this.fetchData()
      }
    },
    currentPage(val) {
      this.page = val
      this.fetchData()
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchCashbackTransaction']),
    fetchData() {
      this.fetchCashbackTransaction({
        limit: this.limit,
        page: this.page,
      })
    },
  },
}
</script>
